import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Element } from 'react-scroll'
import Img from 'gatsby-image'
import Iframe from 'react-iframe'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Link from '@material-ui/core/Link'
import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Button from '../components/Button'
import Team from '../components/Team'
import Gallery from '../components/Gallery'
import Slider from '../components/Slider'
import Map from '../components/Map'
import {
  defaultState,
  slides,
  draggedSpringOptions,
  trailingSpringOptions,
} from '../components/Slider/data'

import GatsbyLink from '../components/Link'

import pagesInfos from '../config/i18n/pagesInfos'

const offset = 30

const useStyles = makeStyles(theme => ({
  eventHeader: {
    height: '150px',
    padding: theme.spacing(2),
    backgroundPosition: 'top',
    borderRadius: '10px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    position: 'relative',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  eventHeaderCover: {
    ...cover(),
    backgroundColor: theme.palette.primary.main,
    opacity: 0.6,
    zIndex: -1,
  },
  h3: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(6),
  },
  slider: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(6),
  },
  button: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  iframe: {
    display: 'block',
    border: 0,
    height: '400px',
    boxShadow:
      '0 62.5px 125px -25px rgba(50, 50, 73, 0.5), 0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6)',
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    zIndex: 1,
    outline: '8px solid ' + theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  imageBackground: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: offset,
    bottom: offset,
    left: offset,
    right: offset,
    //borderRadius: '8px',
    zIndex: -1,

    border: 'solid 8px ' + theme.palette.secondary.main,
  },
}))

const ImplantologiePage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()
  const [index, setIndex] = React.useState(0)
  const data = useStaticQuery(graphql`
    query {
      bgCabinet: file(
        relativePath: {
          eq: "home/modern-metallic-dentist-tools-and-burnishers-on-a-6ZPKC39.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('urgences:head.title')}
        meta={[
          {
            name: 'description',
            content: t('urgences:head.description'),
          },
        ]}
      />
      <Element id="accueil" name="accueil">
        <Hero title={t('urgences:title')} variant="secondary"></Hero>
      </Element>

      <Section bg="#fafdfd">
        {t('urgences:section1.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
        <ul>
          {t('urgences:links', {
            returnObjects: true,
          }).map(link => (
            <Typography component="li" variant="body2" key={link.href}>
              <strong>{link.title}</strong>
              <Link
                color="textSecondary"
                href={link.href}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.label}
              </Link>
            </Typography>
          ))}
        </ul>
        {t('urgences:section1.paragraphs2', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>
      <Section bg="#fff">
        <Heading title={t('urgences:section2.title')} variant="secondary" />
        {t('urgences:section2.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>
      <Section bg="#fafdfd">
        <Heading title={t('urgences:section3.title')} variant="secondary" />
        {t('urgences:section3.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
        <ul>
          {t('urgences:section3.list1', {
            returnObjects: true,
          }).map(list => (
            <Typography component="li" variant="body2" key={list}>
              {list}
            </Typography>
          ))}
        </ul>
        {t('urgences:section3.paragraphs2', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>
      <Section bg="#ffffff">
        <Heading title={t('urgences:section4.title')} variant="secondary" />
        {t('urgences:section4.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>
      <Section bg="#fafdfd">
        <Heading title={t('urgences:section5.title')} variant="secondary" />
        {t('urgences:section5.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
        <ul>
          {t('urgences:links', {
            returnObjects: true,
          }).map(link => (
            <Typography component="li" variant="body2" key={link.href}>
              <strong>{link.title}</strong>
              <Link
                color="textSecondary"
                href={link.href}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.label}
              </Link>
            </Typography>
          ))}
        </ul>
      </Section>
      <Section bg="#ffffff">
        <Heading title={t('urgences:section6.title')} variant="secondary" />
        {t('urgences:section6.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
      </Section>
      <Section bg="#fafdfd">
        <Heading title={t('urgences:section7.title')} variant="secondary" />
        {t('urgences:section7.paragraphs1', {
          returnObjects: true,
        }).map(paragraph => (
          <Typography component="p" variant="body2" paragraph key={paragraph}>
            {paragraph}
          </Typography>
        ))}
        <ul>
          {t('urgences:links', {
            returnObjects: true,
          }).map(link => (
            <Typography component="li" variant="body2" key={link.href}>
              <strong>{link.title}</strong>
              <Link
                color="textSecondary"
                href={link.href}
                target="_blank"
                rel="noreferrer noopener"
              >
                {link.label}
              </Link>
            </Typography>
          ))}
        </ul>
      </Section>
    </React.Fragment>
  )
}

ImplantologiePage.defaultProps = {}

export default ImplantologiePage
